<template>
  <div class="page-container">
    <div class="header-body">
      <el-card>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input
              placeholder="请输入姓名"
              class="input-with-select"
              clearable
              v-model.trim="queryInfo.keyword"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="filterPromoterUserList"
              ></el-button>
            </el-input>
          </el-col>
          <el-col :span="18">
            <div class="add-btn" v-permission="['promoter:promoter:save']">
              <el-button
                type="primary"
                @click="addData.isOpen = !addData.isOpen"
                >添加</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="table-body">
      <el-card>
        <el-table :data="promoterList" style="width: 100%" border stripe>
          <el-table-column type="index" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="phone" label="电话"> </el-table-column>
          <el-table-column label="操作" width="300">
            <template v-slot="scoped">
              <el-button
                v-permission="['promoter:promoter:update']"
                type="warning"
                size="small"
                @click="openUpdateDialog(scoped.row.id)"
                >编辑</el-button
              >
              <el-button
                type="primary"
                size="small"
                @click="getMiniQRcode(scoped.row.id, scoped.row.name)"
                >推广码</el-button
              >
              <el-button
                v-permission="['promoter:promoter:delete']"
                type="danger"
                size="small"
                @click="deletePromoter(scoped.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <div class="pagination-body">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <add-sale-dialog
      :addData="addData"
      @closeAddDialog="closeAddDialog"
    ></add-sale-dialog>
    <update-sale-dialog
      :updateData="updateData"
      @closeUpdateDialog="closeUpdateDialog"
    ></update-sale-dialog>
  </div>
</template>

<script>
import addSaleDialog from './dialog/addSale.vue'
import updateSaleDialog from './dialog/updateSale.vue'
import {
  getPromoterUserList,
  deletePromoter,
  getMiniQRcode
} from '@/api/api.js'
import permission from '@/directive/permission/index.js'
import store from '@/store/index'
export default {
  components: {
    addSaleDialog,
    updateSaleDialog
  },
  data () {
    return {
      permissionList: [],
      promoterList: [],
      queryInfo: {
        currentPage: 1,
        pageSize: 10,
        keyword: ''
      },
      total: 0,
      addData: {
        isOpen: false
      },
      updateData: {
        isOpen: false,
        id: ''
      }
    }
  },
  directives: { permission },
  created () {
    this.getPromoterUserList(this.queryInfo)
    this.permissionList = store.getters['login/getPermission']
  },
  methods: {
    /* 分页选择 */
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getPromoterUserList(this.queryInfo)
    },

    handleCurrentChange (size) {
      this.queryInfo.currentPage = size
      this.getPromoterUserList(this.queryInfo)
    },

    /* 获取微信接口凭证 */
    getMiniQRcode (id, name) {
      getMiniQRcode({
        page: 'pages/index/index',
        scene: id,
        env_version: 'release' // 正式release 开发develop 体验trial
      }).then(res => {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = `data:image/jpeg;base64,${res.data}`
        a.download = `${name}-${Math.floor(Math.random() * 1000000)}.png`
        document.body.appendChild(a)
        a.click()
      })
    },

    /* 筛选推广员列表 */
    filterPromoterUserList () {
      const params = {
        keyword: this.queryInfo.keyword,
        currentPage: 1,
        pageSize: 10
      }
      this.getPromoterUserList(params)
    },

    /* 获取推广员列表 */
    getPromoterUserList (params) {
      getPromoterUserList(params).then(res => {
        console.log(res)
        this.promoterList = res.data.list
        this.total = res.data.total
      })
    },

    /* 打开编辑弹窗 */
    openUpdateDialog (id) {
      this.updateData = {
        isOpen: true,
        id: id
      }
    },

    /* 关闭添加推广员弹窗 */
    closeAddDialog (data) {
      this.addData.isOpen = data.isOpen
      if (data.type === 'success') {
        const params = {
          currentPage: 1,
          pageSize: 10
        }
        this.getPromoterUserList(params)
      }
    },

    /* 关闭更新销售弹窗 */
    closeUpdateDialog (data) {
      this.updateData.isOpen = data.isOpen
      if (data.type === 'success') {
        const params = {
          currentPage: 1,
          pageSize: 10
        }
        this.getPromoterUserList(params)
      }
    },

    /* 删除推广员 */
    deletePromoter (id) {
      this.$confirm('此操作将永久删除该专业, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deletePromoter(id).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            const params = {
              currentPage: 1,
              pageSize: 10
            }
            this.getPromoterUserList(params)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    downloadQrcode (qrCode) {
      window.open(qrCode)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .header-body {
    .add-btn {
      text-align: right;
    }
  }
  .pagination-body {
    .el-pagination {
      text-align: center;
      padding: 20px 0;
    }
  }
}
</style>
